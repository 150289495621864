import React from 'react';
import './Home.css'; 
// import companyLogo from './company-logo1.png'; 

function Home() {
  return (
    <div className="home-container">
      <h1 className='head'>Lakss Recycling Limited</h1>
      {/* <div className='logo2'><img src={companyLogo} alt="Company Logo" /></div> */}
      <div className="background-image"></div>
      {/* <h1>Vikah Ecotech Private Limited</h1> */}
      <h2>Your Sustainable Partner For Recycling Solution</h2>
      <b>
        <p className='font'>
        We are excited to share that LAKSS RECYCLING LIMITED is actively working on developing new and innovative features to enhance your experience. Our team is dedicated to improving our services and bringing you the best possible solutions. We appreciate your patience and understanding as we make these improvements. Your support is invaluable to us, and it drives our commitment to excellence. Thank you for being with us as we embark on this journey of progress and innovation.
        </p>
      </b>
      <b>
        <h2>For Any Inquiry Please Feel Free To Contact Us...</h2>
        <h2>Lakss Recycling Limited</h2>
        <p>ALBERT STREET,</p>
        <p>NEW ZEALAND,</p>
        <p>Contact Number: +64 223136208</p>
        <p>Email-Id: Info@lakssrecycling.com</p>

        
      </b>
    
      
    </div>
  );
}

export default Home;




